import React from 'react'
import styled from 'styled-components'

import { useCronograma } from 'contexts/CronogramaContext'
import { Row } from 'components/layout/FlexComponents'
import TableContainer from './TableContainer'
import Table from 'components/Table/Table'
import {
  getDayAndMonthFromFullDate,
  replaceText,
  textoCapitalizado,
} from 'utils/textProcess'
import { P2, P4 } from 'components/Typography'
import ProgressBarComponent from 'components/ProgressBar/ProgressBar'
import Button from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { useSearchAndFilterTable } from 'contexts/SearchAndFilterTableContext'
import RotatingLoader from 'components/Loading/RotatingLoader'
import StatusTag from 'components/Tag/StatusTag'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;
  text-transform: uppercase;
`

const ProgressBar = styled(ProgressBarComponent)`
  height: 10px;
`

// const DeleteIcon = styled(Trash2)`
//   width: 18px;
//   height: 18px;
//   margin-right: 8px;
//   cursor: pointer;
//   color: ${({ theme, $disabled }) =>
//     $disabled ? theme.colors.neutral.gray : theme.colors.neutral.black};
// `

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4px 10px;
  height: 16px;
`

const StyledButton = styled(Button)`
  margin: 2px 0;
`

const formatData = (pedidos, onClickPedidosParticipacion, width) => {
  const order = {
    CONFIRMADO: 1,
    'EN CURSO': 2,
    CONSOLIDADO: 3,
    OTRO: 4, // Este sería para cualquier otro estado no especificado
  }

  return pedidos
    ?.sort((a, b) => {
      // Ordena por estadoCarritos, dando prioridad a CONFIRMADO, EN CURSO, y luego consolidado
      const estadoA = a.consolidado
        ? order['CONSOLIDADO']
        : order[a.estadoCarritos] || order['OTRO']
      const estadoB = b.consolidado
        ? order['CONSOLIDADO']
        : order[b.estadoCarritos] || order['OTRO']

      return estadoA - estadoB
    })
    .map((proveedor) => ({
      Proveedores: (
        <ProveedoresItem key={proveedor.nombre_comercial}>
          <P2>{textoCapitalizado(proveedor.nombre_comercial.toLowerCase())}</P2>
        </ProveedoresItem>
      ),
      ...(width > 440
        ? {
            Requerimientos: proveedor.consolidado ? (
              <ProgressBar />
            ) : Math.round(proveedor.porcentajeRequerimientos) === 0 ||
              proveedor.estadoCarritos === 'SIN MOVIMIENTOS' ? (
              <P4>No hay datos</P4>
            ) : (
              <>
                <ProgressBar
                  progress={Math.round(proveedor.porcentajeRequerimientos)}
                  tipoProveedor={proveedor.tipo_proveedor}
                />
              </>
            ),
          }
        : {}),
      Estado: (
        <StatusTag
          estado={
            proveedor.consolidado
              ? 'Consolidado'
              : textoCapitalizado(proveedor.estadoCarritos.toLowerCase())
          }
        />
      ),
      ...(width > 440
        ? {
            Accionables: (
              <>
                <StyledButton
                  type="primary"
                  text="Consolidar"
                  size="small"
                  disabled={
                    proveedor.consolidado ||
                    proveedor.enConsolidacion ||
                    Math.round(proveedor.porcentajeRequerimientos) === 0
                  }
                  onClick={async () => {
                    onClickPedidosParticipacion(
                      proveedor.id,
                      proveedor.nombre_comercial,
                    )
                  }}
                />
              </>
            ),
          }
        : {}),
    }))
}

const PedidosTable = ({
  openTable,
  setOpenTable,
  toggleOpen,
  pedidosLoading,
}) => {
  const navigate = useNavigate()
  const { cronograma } = useCronograma()
  const { width } = useWindowWidth()
  const { data } = useSearchAndFilterTable()

  const onClickPedidosParticipacion = (idProveedor, nombreProveedor) => {
    const formattedNombreProveedor = replaceText(nombreProveedor, ' ', '-')
    navigate(`/consolidacion-pedido/${formattedNombreProveedor}`, {
      state: { idProveedor },
    })
  }

  const disable = !cronograma.habilitado

  const headers = [
    {
      name: 'Proveedores',
      isOrderable: false,
    },
    ...(width > 440
      ? [
          {
            name: 'Requerimientos',
            isOrderable: false,
          },
        ]
      : []),
    {
      name: 'Estado',
      isOrderable: false,
    },
    ...(width > 440
      ? [
          {
            name: '',
            isOrderable: false,
          },
          {
            name: '',
            isOrderable: false,
          },
        ]
      : []),
  ]

  return (
    <TableContainer
      $disable={disable}
      title="Consolidar"
      subtitle={`Hasta el ${replaceText(
        getDayAndMonthFromFullDate(cronograma.fecha_fin),
        '-',
        '/',
      )}`}
      centerText={`Cronograma N°${cronograma.id_cronograma}`}
      open={openTable['pedidos']}
      loading={pedidosLoading}
      toggleOpen={disable ? () => {} : () => toggleOpen('pedidos')}
      actionableTable={
        disable
          ? () => {}
          : () =>
              setOpenTable((prevState) => {
                return {
                  ...prevState,
                  pedidos: false,
                }
              })
      }
    >
      {pedidosLoading ? (
        <LoadingWrapper>
          <RotatingLoader />
        </LoadingWrapper>
      ) : data?.length > 0 ? (
        <Table
          data={formatData(data, onClickPedidosParticipacion, width)}
          headers={headers}
          gridTemplate={width > 440 ? '30% 25% 20% 10% 10%' : '60% 40%'}
          $paddingVariant="compact"
        />
      ) : (
        <EmptyTableData>No hay datos de pedidos</EmptyTableData>
      )}
    </TableContainer>
  )
}

export default PedidosTable
