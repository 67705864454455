import React, { createContext, useContext, useState } from 'react'

import { getLocalStorageItem } from 'utils/loadData'
const FiltersContext = createContext()

export const useFilters = () => useContext(FiltersContext)

export const FiltersProvider = ({ children }) => {
  const [comprarFilters, setComprarFilters] = useState(() =>
    getLocalStorageItem('comprarFilters', {
      cronograma_actual: false,
      operación_directa: false,
      marcas_líderes: false,
      todos_provedores: true,
      directa_y_redistribucion: true,
    }),
  )

  const [pedidosFilters, setPedidosFiltersChange] = useState(() =>
    getLocalStorageItem('pedidosFilters', {
      todos: true,
      cronograma_actual: false,
      este_mes: false,
      mes_pasado: false,
      historico: true,
      todos_provedores: true,
      operación_directa: false,
      marcas_líderes: false,
      todos_estados: true,
      aprobado: false,
      facturado: false,
      en_preparacion: false,
      generado: false,
      confeccion: false,
      desaprobado: false,
      cancelado: false,
      no_consolidado: false,
    }),
  )

  const onPedidosFiltersChange = (newFilters) => {
    localStorage.setItem('pedidosFilters', JSON.stringify(newFilters))
    setPedidosFiltersChange(newFilters)
  }

  const onComprarFiltersChange = (newFilters) => {
    localStorage.setItem('comprarFilters', JSON.stringify(newFilters))
    setComprarFilters(newFilters)
  }

  const contextValues = {
    comprarFilters,
    setComprarFilters,
    onComprarFiltersChange,
    pedidosFilters,
    onPedidosFiltersChange,
  }

  return (
    <FiltersContext.Provider value={contextValues}>
      {children}
    </FiltersContext.Provider>
  )
}
