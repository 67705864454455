import React from 'react'

import styled from 'styled-components'
import TableHeader from 'pages/coordinacion/components/Tables/TableHeader'
import TableFooter from 'pages/coordinacion/components/Tables/TableFooter'

const WrapperTable = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  ${(props) =>
    props.$disable &&
    `
    filter: grayscale(100%) contrast(0.7)
  `}
`

const ContentContainer = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: ${({ open }) => (open ? '35vh' : '0')};
  overflow-y: scroll;
`

const TableContainer = ({
  title,
  subtitle,
  centerText,
  open,
  toggleOpen,
  actionableTable,
  buttonFooterText,
  buttonType,
  children,
  $disable,
  className,
  loading,
}) => (
  <WrapperTable className={className} $disable={$disable} open={open}>
    <TableHeader
      title={title}
      windowTo={subtitle}
      cronogramaNumber={centerText}
    />
    <ContentContainer open={open}>{children}</ContentContainer>
    <TableFooter
      open={open}
      toggleOpen={toggleOpen}
      actionableTable={actionableTable}
      buttonFooterText={buttonFooterText}
      buttonType={buttonType}
      loading={loading}
    />
  </WrapperTable>
)

export default TableContainer
