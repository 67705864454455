import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Col } from 'components/layout/FlexComponents'
import { SearchAndFilterTableProvider } from 'contexts/SearchAndFilterTableContext'
import ProductosTable from './components/ProductosTable'
import FilterSection from './components/FilterSectionSurtido'
import HorizontalCarousel from 'components/Carousel/HorizontalCarousel'
import HeaderSurtido from './components/HeaderSurtido'

import { useArticulos } from 'contexts/ArticulosContext'
import { useProveedor } from 'contexts/ProveedorContext'
import Loading from 'components/Loading/Loading'
import { useProveedores } from 'contexts/ProveedoresContext'
import { replaceText } from 'utils/textProcess'
import { Button } from 'components/index'
import { P3 } from 'components/Typography'
import { DATE_OPTIONS } from 'utils/constants'
import { orderByObjectKeyValue } from 'utils/arrayFunctions'
import MobileFilterComponent from 'components/FilterComponent/MobileFilterComponent'

const WrapperComprarPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;
  align-items: flex-start;

  @media (max-width: 440px) {
    padding: 20px 8px;
  }
`

const TableSection = styled.div`
  width: 80%;
  height: 100%;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const FooterSection = styled(Col)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const SurtidoPageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const EmptyPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ButtonIrAComprar = styled(Button)`
  width: 116px;
  padding: 8px 20px;
`

const EmptyTableData = styled(P3)`
  padding: 23px 30px;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
`

const TableWrapper = styled.div`
  padding: 18px;
  border-radius: 0 0 10px 10px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};

  @media (max-width: 440px) {
    padding: 8px;
  }
`

const SurtidoProveedoresPage = () => {
  const { articulos, getArticulos, articulosLoading } = useArticulos()
  const {
    currentProveedor,
    proveedorLoading,
    getProveedor,
    setCurrentProveedor,
  } = useProveedor()

  const [lastPrice, setLastPrice] = useState([])
  const [proveedorCards, setProveedorCards] = useState([])
  const { getProveedores } = useProveedores()

  const navigate = useNavigate()
  const routeParts = location.pathname.split('/')
  const idProveedor = routeParts[routeParts.length - 1]

  function verificarArticulosVacios(articulos) {
    if (articulos) {
      for (let articulo of articulos) {
        if (articulo.articulos.length > 0) {
          return false
        }
      }
    }
    return true
  }

  function getLastPriceDate(data) {
    let lastDate = ''
    if (verificarArticulosVacios(data?.articulos)) {
      return false
    }
    data?.articulos?.forEach((categoria) => {
      categoria.articulos.forEach((articulo) => {
        const fechaPrecio = new Date(articulo.fecha_precio)
        if (!lastDate || fechaPrecio > lastDate) {
          lastDate = fechaPrecio
        }
      })
    })
    return lastDate.toLocaleDateString('es', DATE_OPTIONS)
  }

  function generarCards(proveedores) {
    if (proveedores.length > 0) {
      const filteredProveedores = proveedores.filter(
        (proveedor) => proveedor.id !== currentProveedor.id,
      )
      const cards = filteredProveedores.map((proveedor) => {
        return {
          id: proveedor.id,
          img: proveedor.path,
          title: proveedor.nombre_comercial,
          categorias: proveedor.familias,
          location: proveedor?.localidad?.provincia,
          path_thumb: proveedor?.path,
          puedePedir: { puedePedir: proveedor?.puedePedir },
          habilitado: proveedor?.habilitado,
          navigateFunction: () => {
            setCurrentProveedor({})
            navigate(
              `/comprar/${replaceText(proveedor.nombre_comercial, ' ', '-')}/id/${proveedor.id}`,
            )
          },
        }
      })
      return cards
    } else {
      return []
    }
  }

  useEffect(() => {
    const fetchArticulos = async () => {
      const arti = await getArticulos(idProveedor)
      //TODO no pedir de nuevo proveedores
      const proveedoresLista = await getProveedores({
        paginaActual: 0,
        porPagina: 10,
      })
      setProveedorCards(generarCards(proveedoresLista))
      setLastPrice(getLastPriceDate(arti))
    }
    getProveedor(idProveedor)
    fetchArticulos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProveedor])

  function transformarTexto(texto) {
    return texto
      .toLowerCase()
      .replace(/\s+/g, '_')
      .replace(/[^\w-]/g, '')
  }

  function getFilterCategories(articulos) {
    const estadoOptions = [
      {
        name: 'Activos',
        tag: 'Activos',
        key: 'activos',
        default: true,
      },
      {
        name: 'Suspendidos',
        tag: 'Suspendidos',
        key: 'suspendidos',
      },
    ]

    if (articulos?.articulos) {
      const articulosSet = new Set()
      articulosSet.add({
        name: 'Todos',
        tag: 'Todos',
        key: 'todos',
        default: true,
      })
      articulos?.articulos.forEach((item) => {
        articulosSet.add({
          name: item.descripcion,
          tag: item.descripcion,
          key: transformarTexto(item.descripcion),
        })
      })
      return { Estado: estadoOptions, Productos: Array.from(articulosSet) }
    } else {
      return { Estado: estadoOptions, Productos: [] }
    }
  }

  return (
    <SearchAndFilterTableProvider initialValueData={articulos.articulos}>
      <SurtidoPageWrapper>
        {articulosLoading ||
        proveedorLoading ||
        !currentProveedor?.puedePedir ? (
          <Loading />
        ) : (
          <>
            <WrapperComprarPage>
              <>
                <MobileFilterComponent>
                  <FilterSection categorias={getFilterCategories(articulos)} />
                </MobileFilterComponent>
                <TableSection>
                  <HeaderSurtido
                    proveedor={currentProveedor}
                    lastPrice={lastPrice}
                    motivo={currentProveedor.puedePedir.motivo}
                    puedePedir={
                      currentProveedor.puedePedir.puedePedir &&
                      currentProveedor.habilitado === '1' &&
                      currentProveedor.zonas !== null
                    }
                    zonasProveedor={currentProveedor.zonas}
                    articulosVacios={verificarArticulosVacios(
                      articulos?.articulos,
                    )}
                  />
                  <TableWrapper>
                    {verificarArticulosVacios(articulos?.articulos) ? (
                      <EmptyPage>
                        <EmptyTableData>
                          No hay productos disponibles para este proveedor,
                          comunicarse con la administración
                        </EmptyTableData>
                        <ButtonIrAComprar
                          onClick={() => navigate('/comprar')}
                          type="secondary"
                          text="Ir a Comprar"
                          size="small"
                        />
                      </EmptyPage>
                    ) : (
                      <ProductosTable
                        puedePedir={
                          currentProveedor.puedePedir.puedePedir &&
                          currentProveedor.habilitado === '1' &&
                          currentProveedor.zonas !== null
                        }
                        nombreProveedor={currentProveedor?.nombre_comercial}
                        idProveedor={currentProveedor.id}
                      />
                    )}
                  </TableWrapper>
                  <FooterSection />
                </TableSection>
              </>
            </WrapperComprarPage>
            {proveedorCards && proveedorCards.length > 0 && (
              <HorizontalCarousel
                title="Proveedores"
                cards={orderByObjectKeyValue(
                  proveedorCards,
                  'puedePedir',
                  true,
                )}
              />
            )}
          </>
        )}
      </SurtidoPageWrapper>
    </SearchAndFilterTableProvider>
  )
}

export default SurtidoProveedoresPage
