import { BtnS, P1, P2 } from 'components/Typography'
import { Col } from 'components/layout/FlexComponents'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { compressLongText } from 'utils/textProcess'
import CurrencyFormat from 'react-currency-format'
import { Tooltip } from 'react-tooltip'
import ErrorIcon from 'components/Icons/ErrorIcon'

const Wrapper = styled.div`
  display: grid;
`

const TableWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 50% 2px 50%;
  height: 50vh;
`

const TableTotales = styled.div`
  display: grid;
  grid-template-columns: 50%;
`

const TableContentAsoc = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary.lightBlue};
`

const TableContent = styled.div``

const TableCell = styled(BtnS)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
`

const TableCellAsoc = styled(TableCell)`
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary.lightBlue};
`
// grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
const TableRow = styled.div`
  height: 80px;
  display: grid;
  flex-direction: row;
  grid-template-columns: 25% 30% 15% 15%;
  gap: 15px;
  padding: 6px 30px;
  &:first-of-type {
    position: sticky;
    top: 0;
    left: 0;
  }
  background-color: ${({ theme, $index }) =>
    $index % 2 === 0 || $index === '0'
      ? theme.colors.neutral.almostWhite
      : theme.colors.neutral.white};
`

const TableRowAsoc = styled.div`
  width: fit-content;
  height: 80px;
  display: inline-grid;
  gap: 15px;
  padding: 6px 30px;
  grid-template-columns: ${({ $gridTemplate }) => $gridTemplate};
  &:first-of-type {
    position: sticky;
    top: 0;
    left: 0;
  }
  background-color: ${({ theme, $index, $error }) =>
    $error
      ? `#f8d7da`
      : $index % 2 === 0 || $index === '0'
        ? theme.colors.neutral.almostWhite
        : theme.colors.neutral.white};
`

const NumberOfCell = styled(P2)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  max-width: fit-content;
`

const NumberOfCellApedir = styled.input`
  width: 25px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.colors.primary.lightBlue};
  padding: 8px;
  border-radius: 4px;

  input {
    color: ${({ theme }) => theme.colors.primary.lightBlue};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }
`

const PedidoCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const SubtitleSection = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-transform: uppercase;
`
const TableCellCoope = styled(BtnS)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
`

const DottedVerticalLine = styled.div`
  border-left: 2px dotted ${({ theme }) => theme.colors.primary.lightBlue};
  height: 100%;
`

const TotalesTableSection = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 90% 1fr 2.5fr 1fr 1fr 1fr;
  justify-items: center;
  padding: 20px 30px 0;
  & > :nth-child(1) {
    grid-column-start: 2;
  }
  color: ${({ theme }) => theme.colors.neutral.black};
  width: 100%;
`

const TotalesTitle = styled(P1)`
  text-wrap: nowrap;
  align-items: center;
  display: flex;
  font-weight: bold;
`

const StyledInfoGridBox = styled.div`
  display: flex;
  grid-column: span 1;
  align-items: center;
  text-align: center;
  height: 100%;
`

const InfoBox = styled(Col)`
  padding: 10px;
  text-align: start;
`

const Line = styled.div`
  height: 31px;
  border: 1px solid ${({ theme }) => theme.colors.primary.darkBlue};
  margin: ${({ $vertical }) => ($vertical ? '0 10px' : '10px 0')};
`

const TotalPorArticulo = styled.span`
  font-weight: 1000;
  color: ${({ theme, $multiplo }) =>
    $multiplo
      ? theme.colors.alarmsStatuses.green
      : theme.colors.alarmsStatuses.red};
`

const DistribuirPedidoAgrupacionTable = ({
  matriz,
  headers,
  articulosDivididos,
  setArticulosDivididos,
  setTotales,
  totales,
  requerimientosMinimos,
  setReqCalculados,
  checkConsolidacionDeshabilitada,
}) => {
  const columnWidth = 200
  const fixedColumnCount = 4

  function handleChange(id_producto, id_asociada, cantidad) {
    setArticulosDivididos((prevState) => {
      const newState = { ...prevState }
      checkConsolidacionDeshabilitada()
      if (cantidad === 0) {
        if (
          newState[id_asociada] !== undefined &&
          newState[id_asociada][id_producto] !== undefined
        ) {
          delete newState[id_asociada][id_producto]
          if (Object.keys(newState[id_asociada]).length === 0) {
            delete newState[id_asociada]
          }
        }
      } else {
        if (newState[id_asociada] === undefined) {
          newState[id_asociada] = {}
        }
        newState[id_asociada][id_producto] =
          cantidad === '' ? 0 : parseInt(cantidad)
      }
      return newState
    })
  }

  function buscarCantidad(id_producto, id_asociada) {
    if (
      articulosDivididos !== undefined &&
      articulosDivididos[id_asociada] !== undefined &&
      articulosDivididos[id_asociada][id_producto] !== undefined
    ) {
      return articulosDivididos[id_asociada][id_producto]
    } else {
      return 0
    }
  }

  function calcularTotales() {
    let totalesCalculados = {
      bultos: 0,
      subtotal: 0,
      total: 0,
      pallets: 0,
      matriz,
      bultosPorArticulo: {},
    }
    Object.keys(articulosDivididos).forEach((asociada) => {
      const pedidosAsociada = articulosDivididos[asociada]
      Object.keys(pedidosAsociada).forEach((art) => {
        const dataArticulo = matriz.find(
          (articulo) => articulo.id_articulo === art,
        )
        totalesCalculados.bultos += parseInt(pedidosAsociada[art])
        if (!totalesCalculados.bultosPorArticulo[art]) {
          totalesCalculados.bultosPorArticulo[art] = 0
        }
        totalesCalculados.bultosPorArticulo[art] += parseInt(
          pedidosAsociada[art],
        )
        totalesCalculados.subtotal +=
          parseInt(pedidosAsociada[art]) *
          parseFloat(dataArticulo?.precio) *
          parseInt(dataArticulo?.bultos_camada)
        totalesCalculados.total +=
          parseInt(pedidosAsociada[art]) *
          parseFloat(dataArticulo?.precio) *
          parseInt(dataArticulo?.bultos_camada) *
          (1 + dataArticulo?.iva)
        totalesCalculados.pallets +=
          parseInt(pedidosAsociada[art]) /
          (parseInt(dataArticulo?.bultos_piso) *
            parseInt(dataArticulo?.pisos_pallet))
      })
    })
    setTotales(totalesCalculados)
  }

  function buscarCantidadArticulo(id_producto) {
    if (
      totales &&
      totales.bultosPorArticulo &&
      totales.bultosPorArticulo[id_producto]
    ) {
      return totales.bultosPorArticulo[id_producto]
    } else {
      return 0
    }
  }

  function calcularPorcentajeRequisitos(reqMin, totales) {
    const formatearRequisitos = (porcentaje) => {
      return porcentaje > 0 && porcentaje < 1 ? 1 : Math.round(porcentaje)
    }
    let porcentajeRequisitos = 100

    if (reqMin) {
      const bultosMinimo = reqMin.bultos_minimo
      const valorizadoMinimo = reqMin.valorizado_minimo
      const palletMinimo = reqMin.pallet_minimo

      if (bultosMinimo && bultosMinimo !== '0') {
        porcentajeRequisitos = Math.round((totales.bultos / bultosMinimo) * 100)
      } else if (valorizadoMinimo && valorizadoMinimo !== '0') {
        porcentajeRequisitos = Math.round(
          (totales.subtotal / valorizadoMinimo) * 100,
        )
      } else if (palletMinimo && palletMinimo !== '0') {
        porcentajeRequisitos = (totales.pallets / palletMinimo) * 100
      }
    }
    setReqCalculados(formatearRequisitos(porcentajeRequisitos))
  }

  useEffect(() => {
    calcularTotales()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulosDivididos])

  useEffect(() => {
    calcularPorcentajeRequisitos(requerimientosMinimos, totales)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totales])

  return (
    <Wrapper>
      <TableWrapper>
        <TableContent>
          <TableRow>
            {headers?.slice(0, fixedColumnCount).map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
          {matriz.map((row, index) => (
            <TableRow $index={index} key={index}>
              {headers
                ?.slice(0, fixedColumnCount - 1)
                .map((column, columnIndex) => {
                  const columName = column.toLowerCase()
                  return (
                    <TableCell key={columnIndex}>
                      <NumberOfCell>{row[columName]}</NumberOfCell>
                    </TableCell>
                  )
                })}
              <TableCell key={fixedColumnCount}>
                <NumberOfCell>
                  <TotalPorArticulo
                    $multiplo={
                      buscarCantidadArticulo(row['id_articulo']) %
                        row['múltiplo'] ===
                      0
                    }
                  >
                    {buscarCantidadArticulo(row['id_articulo'])}
                  </TotalPorArticulo>
                  {buscarCantidadArticulo(row['id_articulo']) %
                    row['múltiplo'] !==
                    0 && (
                    <>
                      <ErrorIcon
                        data-tooltip-id={`mult-error-tooltip-${row['id_articulo']}`}
                        data-tooltip-content="Error de múltiplos"
                        data-tooltip-place="top"
                      >
                        !
                      </ErrorIcon>
                      <Tooltip
                        id={`mult-error-tooltip-${row['id_articulo']}`}
                      />
                    </>
                  )}
                </NumberOfCell>
              </TableCell>
            </TableRow>
          ))}
        </TableContent>
        <DottedVerticalLine />
        <TableContentAsoc>
          <TableRowAsoc
            $gridTemplate={`repeat(${
              headers?.length - fixedColumnCount
            }, ${columnWidth}px)`}
          >
            {headers?.slice(fixedColumnCount).map((header, index) => (
              <TableCellAsoc key={index}>
                <TableCellCoope>{compressLongText(header, 50)}</TableCellCoope>
                <SubtitleSection>
                  <div>pedido</div>
                  <div>a pedir</div>
                </SubtitleSection>
              </TableCellAsoc>
            ))}
          </TableRowAsoc>

          {matriz.map((row, rowIndex) => {
            return (
              <TableRowAsoc
                $gridTemplate={`repeat(${
                  headers?.length - fixedColumnCount
                }, ${columnWidth}px)`}
                key={rowIndex}
                $index={rowIndex}
                $error={
                  buscarCantidadArticulo(row['id_articulo']) %
                    row['múltiplo'] !==
                  0
                }
              >
                {headers?.slice(fixedColumnCount).map((header, index) => {
                  const asociadosValues = Object.values(row['asociadas'])
                  const asociadaData = asociadosValues[index]
                  return (
                    asociadaData && (
                      <PedidoCell key={index}>
                        <NumberOfCell>{asociadaData?.pedido}</NumberOfCell>
                        <NumberOfCellApedir
                          disabled={asociadaData?.pedido === 0}
                          value={buscarCantidad(
                            asociadaData?.id_articulo,
                            asociadaData?.id_subasociada,
                          )}
                          onChange={(e) =>
                            handleChange(
                              asociadaData?.id_articulo,
                              asociadaData?.id_subasociada,
                              e.target.value,
                            )
                          }
                        />
                      </PedidoCell>
                    )
                  )
                })}
              </TableRowAsoc>
            )
          })}
        </TableContentAsoc>
      </TableWrapper>
      <TableTotales>
        <TableContent>
          <TotalesTableSection>
            <TotalesTitle>TOTALES</TotalesTitle>
            <StyledInfoGridBox>
              <InfoBox>
                <BtnS>BULTOS</BtnS>
                <P1>{totales.bultos}</P1>
              </InfoBox>
              <Line $vertical="true" />
              <InfoBox>
                <BtnS>NETO</BtnS>
                <CurrencyFormat
                  value={parseFloat(totales.subtotal)}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={' $'}
                  decimalScale={3}
                  fixedDecimalScale={true}
                />
              </InfoBox>
              <Line $vertical="true" />
              <InfoBox>
                <BtnS>TOTAL</BtnS>
                <CurrencyFormat
                  value={parseFloat(totales.total)}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={' $'}
                  decimalScale={3}
                  fixedDecimalScale={true}
                />
              </InfoBox>
            </StyledInfoGridBox>
          </TotalesTableSection>
        </TableContent>
      </TableTotales>
    </Wrapper>
  )
}

export default DistribuirPedidoAgrupacionTable
