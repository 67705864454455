export function replaceText(
  str,
  strToReplace = 'cooperativa',
  textToReplaceWith = 'coop.',
) {
  if (str) {
    const regex = new RegExp(`\\b${strToReplace}\\b`, 'gi')
    return str.replace(regex, textToReplaceWith)
  }
}

export function compareStringsIgnoreCaseAndAccent(str1, str2) {
  const normalizeString = (str) =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

  const normalizedStr1 = normalizeString(str1)
  const normalizedStr2 = normalizeString(str2)

  return normalizedStr1 === normalizedStr2
}

export function getDayAndMonthFromFullDate(cadenaFecha) {
  if (cadenaFecha) {
    const partes = cadenaFecha.split('-')
    const año = partes[0]
    const mes = partes[1]
    const dia = partes[2]

    return dia + '/' + mes + '/' + año
  }
  return ''
}

export const queryString = (data) =>
  Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&')

export const textoCapitalizado = (text) => {
  // las palabras dentro de esta lista quedan completamente en mayúsculas en lugar de capitalizadas
  const ALL_CAPS_WORDS = ['sa', 's.a', 's.a.', 's.r.l.', 'srl', 'ltda']
  return text
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (!ALL_CAPS_WORDS.some((w) => w === word)) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      } else {
        return word.toUpperCase()
      }
    })
    .join(' ')
}

export function capitalizeFirstLetter(string) {
  return string.toLowerCase()[0].toUpperCase() + string.toLowerCase().slice(1)
}

export function compressLongText(text, maxLength) {
  return text.length <= maxLength ||
    typeof text !== 'string' ||
    text.length === 0
    ? text
    : text.slice(0, maxLength).trim() + '...'
}

export const isLongText = (string, length) => {
  return typeof string === 'string' && string.length > length
}

export function formatDate(date) {
  return replaceText(getDayAndMonthFromFullDate(date), '-', '/')
}

export function getNumbersFromString(str) {
  return str.replace(/\D/g, '')
}

export function removeAccents(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u')
}

// revisar funciones (en algunos casos no están remplazando el texto)
export function reemplazarInterrogacionPorÑ(texto) {
  // Usamos una expresión regular que busca "?" seguido de cualquier letra (\w)
  const regex = /\?([a-zA-Z])/g

  // Reemplazamos todas las coincidencias con la letra Ñ
  return texto.replace(regex, 'Ñ$1')
}

// revisar funciones (en algunos casos no están remplazando el texto)
export function reemplazarÑPorInterrogacion(texto) {
  // Usamos una expresión regular que busca "Ñ" seguido de cualquier letra (\w)
  const regex = /Ñ([a-zA-Z])/g

  // Reemplazamos "Ñletra" por "?letra"
  return texto.replace(regex, '?$1')
}
