import React, { useState } from 'react'
import styled from 'styled-components'

import { Lock, User } from 'feather-icons-react'
import Button from 'components/Button/Button'
import { useAuth } from 'contexts/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import Input from 'components/Input/Input'
import { H1, H2, P3 } from 'components/Typography'
import backgroundImg from 'assets/banner_login.jpeg'
import RotatingLoader from 'components/Loading/RotatingLoader'
import DropdownNotification from 'components/Notification/DropdownNotification'

const Wrappper = styled.div`
  display: flex;
  height: fit-content;
  justify-content: center;
  width: 100%;
  gap: 95px;
  margin: 5%;

  @media (max-width: 440px) {
    flex-direction: column;
    gap: 0px;
  }
`

const LoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(${backgroundImg});
  background-size: contain;
  background-repeat: no-repeat;
`

const LoginForm = styled.div`
  display: flex;
  width: 352px;
  height: 275px;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary.darkBlue};
  padding: 90px 48px 32px;
  gap: 18px;
  box-shadow: 0px 16px 24px 4px rgba(90, 82, 128, 0.12);

  @media (max-width: 1366px) {
    padding: 68px 36px 18px;
  }

  @media (max-width: 440px) {
    padding: 40px 18px 10px;
    gap: 14px;
  }
`

const LoginButton = styled(Button)`
  margin: ${({ $error }) => {
      return 18 + ($error ? 0 : 42)
    }}px
    0 18px;
  margin-top: 18px;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06);
`

const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
`

const ForgotMyPasswordButton = styled(Button)`
  color: ${({ theme }) => theme.colors.neutral.white};
`

const RigthSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;

  @media (max-width: 440px) {
    margin: 80px 0 20px;
  }
`

const WelcomeMessage = styled(H1)`
  max-width: 570px;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const InputLogin = styled(Input)`
  width: 340px;
  height: 32px;
  padding: 8px 16px;

  @media (max-width: 1366px) {
    width: 320px;
  }

  @media (max-width: 440px) {
    width: 240px;
  }
`

const DropdownNotificationCustom = styled(DropdownNotification)`
  width: 370px;

  @media (max-width: 1366px) {
    width: 360px;
  }

  @media (max-width: 440px) {
    width: 280px;
  }
`

const TextPaso1 = styled(P3)`
  text-align: center;
  width: 80%;
  color: ${({ theme }) => theme.colors.neutral.white};
`

const TextPaso2 = styled(H2)`
  text-align: center;
  width: 80%;
  color: ${({ theme }) => theme.colors.neutral.white};
`

const CustomRotatingLoader = styled(RotatingLoader)`
  width: 63px;
  height: 24px;
`

const Login = () => {
  const navigate = useNavigate()
  const { login, loginLoading, token, forgotPassword } = useAuth()
  const [loginError, setLoginError] = useState(false)
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [currentForm, setCurrentForm] = useState(0)
  const [isResendLoading, setIsResendLoading] = useState(false)

  const handleLogin = async () => {
    await login(userName, password)
      .then(() => {
        navigate('/')
      })
      .catch((error) => {
        setLoginError(
          error.response.data.messages.errors.includes(
            'Please check your password',
          )
            ? 'El usuario o la contraseña no son correctos. Intentá nuevamente'
            : 'Se produjo un erro en el login',
        )
        console.error('Error de autenticación:', error)
      })
  }

  const handleForgotPassword = async (isResendButton) => {
    try {
      isResendButton && setIsResendLoading(true)
      await forgotPassword(userName)
      setCurrentForm(2)
    } catch (e) {
      setLoginError(
        e.response.data.messages.errors.includes(
          'El nombre de usuario no esta registrado en el sistema',
        )
          ? 'Este usuario no existe en el sistema'
          : e.response.data.messages.errors.includes('sin email asignado')
            ? 'El usuario no tien email asignado para recuperar la contraseña'
            : 'Se produjo un error al recuperar la contraseña',
      )
      console.log(e)
    }
    setIsResendLoading(false)
  }

  const handleNext = () => {
    setCurrentForm(2)
  }

  const handleResetToLogin = () => {
    setCurrentForm(0)
    setUserName('')
    setPassword('')
    setLoginError(false)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (currentForm === 0) {
        handleLogin()
      } else if (currentForm === 1) {
        handleNext()
      }
    }
  }

  if (token) {
    return <Navigate to="/" />
  }
  return (
    <LoginPage>
      <Wrappper>
        <RigthSection>
          <WelcomeMessage>
            Te damos la bienvenida a la Central de Compras FACC
          </WelcomeMessage>
        </RigthSection>

        {currentForm === 0 && (
          <LoginForm>
            <InputLogin
              type="text"
              border={true}
              placeholder="Nombre de usuario"
              onChange={(event) => {
                setLoginError(false)
                setUserName(event.target.value)
              }}
              value={userName}
              icon={<User size={20} />}
              $error={!!loginError}
            />

            <InputLogin
              type="password"
              border={true}
              value={password}
              onChange={(event) => {
                setLoginError(false)
                setPassword(event.target.value)
              }}
              placeholder="Contraseña"
              icon={<Lock size={20} />}
              onKeyPress={handleKeyPress}
              $error={!!loginError}
            />
            {!!loginError && (
              <DropdownNotificationCustom
                text="El usuario o la contraseña no son correctos."
                type="error"
              />
            )}

            <LoginButton
              $error={!!loginError}
              onClick={handleLogin}
              text={loginLoading ? <CustomRotatingLoader /> : 'Ingresar'}
              type="primary"
              disabled={userName === '' || password === ''}
            />

            <ForgotMyPasswordButton
              onClick={() => {
                setCurrentForm(1)
                setLoginError(false)
              }}
              text="Olvidé mi contraseña"
              type="tertiary"
              size="small"
            />
          </LoginForm>
        )}

        {currentForm === 1 && (
          <LoginForm>
            <TextPaso1>
              Si querés restablecer tu contraseña ingresá tu usuario
            </TextPaso1>
            <InputLogin
              type="text"
              placeholder="Nombre de usuario"
              onChange={(event) => {
                setUserName(event.target.value)
              }}
              value={userName}
              icon={<User size={20} />}
            />
            <LoginButton
              onClick={() => handleForgotPassword(false)}
              text={loginLoading ? <CustomRotatingLoader /> : 'Siguiente'}
              type="primary"
              disabled={userName === ''}
            />
            {!!loginError && (
              <DropdownNotification text={loginError} type="error" />
            )}
          </LoginForm>
        )}

        {currentForm === 2 && (
          <LoginForm>
            <TextPaso2>Revisá tu mail para obtener tu contraseña</TextPaso2>
            <TextPaso1>
              Te hemos enviado un mail a tu casilla de correo vinculada para que
              puedas restablecer tu contraseña
            </TextPaso1>
            <WrapperButtons>
              {'username === ' + userName === ''}
              <LoginButton
                onClick={() => handleForgotPassword(true)}
                text={
                  loginLoading && isResendLoading ? (
                    <CustomRotatingLoader />
                  ) : (
                    'Reenviar email'
                  )
                }
                type="tertiary"
                disabled={userName === ''}
              />
              <LoginButton
                onClick={handleResetToLogin}
                text={
                  loginLoading && !isResendLoading ? (
                    <CustomRotatingLoader />
                  ) : (
                    'Iniciar sesión'
                  )
                }
                type="primary"
              />
            </WrapperButtons>
          </LoginForm>
        )}
      </Wrappper>
    </LoginPage>
  )
}

export default Login
