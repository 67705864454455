import React from 'react'

import { useAuth } from 'contexts/AuthContext'
import styled from 'styled-components'

const Container = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`

const Title = styled.h1`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-weight: bold;
`

const Subtitle = styled.h2`
  font-size: 16px;
  margin-bottom: 20px;
`

const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 20px;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

const Tutoriales = () => {
  const { user } = useAuth()

  const RolView = [
    [
      {
        video: 'https://www.youtube.com/embed/MU0kiVHJnmU',
        titulo: '',
      },
      {
        video: 'https://www.youtube.com/embed/jhIEX-pwUng?si=JaXCFJbhRm6qz-HC',
        titulo: '¿Cómo comprar en la Central de Compras?',
      },
    ],
    [
      {
        video: 'https://www.youtube.com/embed/T4NfdIZ-uJ4',
        titulo: '',
      },
      {
        video: 'https://www.youtube.com/embed/jhIEX-pwUng?si=JaXCFJbhRm6qz-HC',
        titulo: '¿Cómo comprar en la Central de Compras?',
      },
    ],
    [
      {
        video: 'https://www.youtube.com/embed/rlwwpZmEfz8',
        titulo: '',
      },
    ],
  ]

  return (
    <Container>
      <Title>Recorrido por la Central de Compras de la FACC</Title>
      {RolView[parseInt(user?.rol?.id_rol) - 1].map((video) => {
        return (
          <>
            <Subtitle>{video.titulo}</Subtitle>

            <VideoContainer>
              <StyledIframe
                src={video.video || null}
                title="Video de presentación"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoContainer>
          </>
        )
      })}
    </Container>
  )
}

export default Tutoriales
