import React, { useEffect } from 'react'
import styled from 'styled-components'
import DistribuirPedidoAgrupacionTable from './DistribuirPedidoAgrupacionTable'
import { Button } from 'components/index'
import { useNavigate } from 'react-router-dom'
import { usePedidos } from 'contexts/PedidosContext'
import { showToast } from 'components/Notification/ToastNotification'

const TableSection = styled.div`
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 50px 30px;
`

function generarEncabezados(resultadoPedido) {
  if (resultadoPedido?.length > 0) {
    const encabezadosSet = new Set(['EAN', 'Producto', 'Múltiplo', 'Totales'])
    resultadoPedido.forEach((articulo) => {
      encabezadosSet.add(articulo.nombre_comercial)
    })

    const encabezados = Array.from(encabezadosSet)

    return encabezados
  }
  return []
}
function formatData(matriz) {
  const resultados = []
  if (matriz?.result) {
    Object.keys(matriz.result).forEach((art) => {
      const articulo = matriz.result[art]
      const resultado = {
        id_articulo: articulo.id_articulo,
        ean: articulo.ean13,
        múltiplo: articulo.multiplo,
        producto: articulo.descripcion,
        precio: parseFloat(articulo.precio),
        iva: articulo.iva ? parseFloat(articulo.iva) / 100 : 0,
        bultos_camada: articulo.bultos_camada,
        bultos_piso: articulo.bultos_piso,
        pisos_pallet: articulo.pisos_pallet,
        pedido: 0,
        totales: 0,
        asociadas: {},
      }

      matriz.afiliados.forEach((subasociada) => {
        resultado.pedido += articulo[subasociada.id]
          ? parseInt(articulo[subasociada.id].cantidad)
          : 0
        resultado.totales = resultado.pedido
        resultado.asociadas[subasociada.id] = {
          nombre_comercial: subasociada.nombre_comercial,
          id_subasociada: subasociada.id,
          id_articulo: articulo.id_articulo,
          pedido: articulo[subasociada.id]
            ? parseInt(articulo[subasociada.id].cantidad)
            : 0,
          a_pedir: 0,
        }
      })

      resultados.push(resultado)
    })
  }

  return resultados
}

const EditarPedidoAgrupacionTable = ({
  matriz,
  proveedor,
  reqCalculados,
  setReqCalculados,
  articulosDivididos,
  setArticulosDivididos,
  totales,
  setTotales,
  setDisabledConsolidar,
}) => {
  // const [errorMessage, setErrorMessage] = useState(false)

  const navigate = useNavigate()

  const { pedidoConsolidado } = usePedidos()
  const matrizFormateada = formatData(matriz)
  const requerimientosMinimos = matriz?.requerimientosLogisticos

  useEffect(() => {
    Object.keys(articulosDivididos).length === 0 && copiarValores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkConsolidacionDeshabilitada()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proveedor, reqCalculados, totales])

  useEffect(() => {
    if (pedidoConsolidado) {
      setTimeout(function () {
        navigate(`/pedidos/`)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function copiarValores() {
    let newArticulosDivididos = {}
    if (matriz?.result) {
      Object.keys(matriz.result).forEach((art) => {
        const articulo = matriz.result[art]
        matriz.afiliados.forEach((subasociada) => {
          if (articulo[subasociada.id]) {
            if (!newArticulosDivididos[subasociada.id]) {
              newArticulosDivididos[subasociada.id] = {}
            }
            newArticulosDivididos[subasociada.id][articulo.id_articulo] =
              parseInt(articulo[subasociada.id].cantidad)
          }
        })
      })
      setArticulosDivididos(newArticulosDivididos)
    }
  }

  function borrarValores() {
    setArticulosDivididos({})
  }

  function checkConsolidacionDeshabilitada() {
    const errores = []

    if (reqCalculados < 100) {
      errores.push('req')
    }
    if (validarErrores()) {
      errores.push('mult')
    }

    if (totales.bultos === 0) {
      errores.push('deshabilitado')
    }
    setDisabledConsolidar(errores)
  }

  function validarErrores() {
    return (
      matrizFormateada &&
      totales?.bultosPorArticulo &&
      matrizFormateada?.some((row) => {
        return (
          totales?.bultosPorArticulo[row['id']] &&
          parseInt(totales?.bultosPorArticulo[row['id']]) %
            parseInt(row['múltiplo']) !==
            0
        )
      })
    )
  }

  return (
    <>
      {matriz && (
        <>
          <TableSection>
            <DistribuirPedidoAgrupacionTable
              matriz={matrizFormateada}
              headers={generarEncabezados(matriz?.afiliados) || {}}
              articulosDivididos={articulosDivididos}
              setArticulosDivididos={setArticulosDivididos}
              totales={totales}
              setTotales={setTotales}
              requerimientosMinimos={requerimientosMinimos}
              setReqCalculados={setReqCalculados}
              checkConsolidacionDeshabilitada={checkConsolidacionDeshabilitada}
            />

            <ButtonWrapper>
              <Button
                text={'Borrar cantidades cargadas'}
                type={'tertiary'}
                onClick={() => {
                  borrarValores()
                  showToast(`¡Se borraron los valores con éxito!`, 'validacion')
                }}
              />
              <Button
                text={'Copiar valores del pedido'}
                type={'tertiary'}
                onClick={() => {
                  copiarValores()
                  showToast(`¡Se copiaron los valores con éxito!`, 'validacion')
                }}
              />
            </ButtonWrapper>
          </TableSection>
        </>
      )}
    </>
  )
}

export default EditarPedidoAgrupacionTable
