import React, { useState } from 'react'
import styled from 'styled-components'
import { Trash2 } from 'feather-icons-react/build/IconComponents'
import Table from 'components/Table/Table'
import IconButton from 'components/Button/IconButton'
import Link from 'components/Button/Link'
import LinkBig from 'components/Button/LinkBig'
import CounterInput from 'components/CounterInput/CounterInput'
import { BtnS, H3, H6, P1, P3, P4 } from 'components/Typography'
import { Button } from 'components/index'
import ProgressBarComponent from 'components/ProgressBar/ProgressBar'
import { Col } from 'components/layout/FlexComponents'
import HeaderTable from 'components/Table/HeaderTable'
import { sumarPrecios } from '../utils'
import { textoCapitalizado } from 'utils/textProcess'
import { useCarrito } from 'contexts/CarritoContext'
import { useNavigate } from 'react-router-dom'
import { replaceText } from 'utils/textProcess'
import { useModal } from 'contexts/ModalContext'
import DeleteProveedorModal from 'components/Modal/DeleteProveedorModal'
import RotatingLoader from 'components/Loading/RotatingLoader'
import DeleteProductModal from 'components/Modal/DeleteProductModal'
import { useAuth } from 'contexts/AuthContext'
import ConsolidarAsociadaModal from 'components/Modal/ConsolidarAsociadaModal'
import { PedidosProvider } from 'contexts/PedidosContext'
import ModalNotification from 'components/Notification/ModalNotification'
import DropdownNotification from 'components/Notification/DropdownNotification'
import CurrencyFormat from 'react-currency-format'
import { useCronograma } from 'contexts/CronogramaContext'
import { useArticulos } from 'contexts/ArticulosContext'
import { useWindowWidth } from 'contexts/WindowWidthContext'
import { showToast } from 'components/Notification/ToastNotification'

const StyledHeaderProveedores = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 60px;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.neutral.almostWhite}`};
  border-radius: 20px 20px 0 0;
  color: ${({ theme }) => theme.colors.primary.darkBlue};

  @media (max-width: 1366px) {
    padding: 20px 24px;
  }

  @media (max-width: 440px) {
    flex-direction: column;
    padding: 18px 12px;
    gap: 18px;
  }
`

const ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 36px;

  @media (max-width: 1366px) {
    gap: 14px;
  }

  @media (max-width: 440px) {
    flex-direction: column-reverse;
    gap: 18px;
    margin-left: 0;
    margin-right: auto;
  }
`

const StyledBultosItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: 440px) {
    padding-left: 10px;
    min-width: -webkit-fill-available;
  }
`

const StyledInfoGridBox = styled.div`
  display: flex;
  grid-column: span 1;
  align-items: center;
  text-align: center;
  height: 100%;

  @media (max-width: 440px) {
    margin-right: auto;
  }
`

const StyledButtonSection = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: 440px) {
    margin-right: auto;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`

const EmptyTableData = styled(P3)`
  padding: 23px 30px;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
`

const ImagenProducto = styled.img`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary.blue};
`

const WrapperImage = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
`

const ProveedoresItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 0 15px;

  @media (max-width: 440px) {
    margin-left: 8px;
    align-self: start;
  }
`

const InfoBox = styled(Col)`
  padding: 10px;
`

const Line = styled.div`
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.primary.darkBlue};
  margin: ${({ $vertical }) => ($vertical ? '0 6px' : '6px 0')};
`

const ProgressBarBultos = styled(ProgressBarComponent)`
  height: 10px;
`

const TableStyled = styled(Table)`
  width: auto;
  height: 200px;
  touch-action: pan-y;
  overflow: ${({ $isExpanded }) => ($isExpanded ? 'scroll' : 'hidden')};
  transition: max-height 0.3s ease-in-out;
  max-height: ${({ $isExpanded }) => ($isExpanded ? '500px' : '0')};
  background-color: ${({ theme }) => `${theme.colors.neutral.almostWhite}`};
  border-radius: 0 0 20px 20px;
  padding-bottom: ${({ $isExpanded }) => ($isExpanded ? '20px' : '0')};
`

const EmptyPage = styled.div`
  display: flex;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LinkProduct = styled(LinkBig)`
  margin: 0;
  font-family: 'Roboto-bold';
  text-decoration: none;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.neutral.black};
  :hover {
    color: ${({ theme }) => theme.colors.neutral.black};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.neutral.black};
  }
`

const ButtonIrAComprar = styled(Button)`
  width: 116px;
  padding: 8px 20px;
`

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-weight: 600;
  font-size: 14px;
`

const DeleteIcon = styled(Trash2)`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  stroke-width: 2.5px;
`

const LinkMenu = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.neutral.black};
  :hover {
    color: ${({ theme }) => theme.colors.neutral.black};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.neutral.black};
  }
`

const calcularTotales = (productos) => {
  const totales = productos.reduce(
    (acc, producto) => {
      acc.bultos += parseInt(producto.cantidad_carrito, 10)
      acc.subtotal +=
        parseFloat(producto.cantidad_carrito) *
        parseFloat(producto.precio) *
        parseInt(producto.bultos_camada)
      acc.pallets +=
        parseInt(producto.cantidad_carrito) /
        (parseInt(producto.bultos_piso) * parseInt(producto.pisos_pallet))
      return acc
    },
    { bultos: 0, subtotal: 0, pallets: 0 },
  )

  const iva = 0.21
  totales.subtotal_float = totales.subtotal
  totales.total = new Intl.NumberFormat().format(totales.subtotal * (1 + iva))
  totales.subtotal = new Intl.NumberFormat().format(totales.subtotal)

  return totales
}

const BorrarIcon = ({ clearFunction }) => {
  const [loading, setLoading] = useState(false)

  return (
    <StyledButton
      onClick={async () => {
        setLoading(true)
        await clearFunction()
        setLoading(false)
      }}
    >
      {loading ? <RotatingLoader /> : <Trash2 size={16} />}
    </StyledButton>
  )
}
const navigateToProveedorPage = (idProveedor, nombreProveedor, navigate) => {
  navigate(`/detalle-proveedor/${replaceText(nombreProveedor, ' ', '-')}`, {
    state: { idProveedor },
  })
}
const navigateToProductoPage = (nombreProveedor, idArticulo, navigate) => {
  navigate(`/comprar/${replaceText(nombreProveedor, ' ', '-')}/${idArticulo}`)
}

const formatData = (
  data,
  idProveedor,
  nombreProveedor,
  onClickActualizarCarrito,
  handleDeleteCarrito,
  user,
  width,
  navigate,
) =>
  data?.map((producto) => {
    return {
      ...(width > 440 && {
        Imagen: (
          <WrapperImage>
            <ImagenProducto
              src={producto.path_thumb}
              alt={producto.descripcion}
            />
          </WrapperImage>
        ),
      }),
      Descripcion: (
        <Description>
          <LinkProduct
            onClick={() =>
              navigateToProductoPage(
                nombreProveedor,
                producto.id_articulo,
                navigate,
              )
            }
          >
            {textoCapitalizado(producto.descripcion)}
          </LinkProduct>
          <P1>{producto.observacion}</P1>
        </Description>
      ),
      'Uni/bulto': (
        <Description>
          <P1>{producto?.bultos_camada}</P1>
        </Description>
      ),
      Cantidad: (
        <CounterInput
          amount={producto.cantidad_carrito}
          onClick={async (cant) => {
            await onClickActualizarCarrito(
              producto.id_articulo,
              cant,
              idProveedor,
            )
          }}
        />
      ),
      ...(width > 440 && {
        Observacion: (
          <P4>
            {producto.multiplo !== '0' && producto.multiplo !== '1'
              ? `Múltiplo: ${producto.multiplo}`
              : ''}
          </P4>
        ),
      }),
      Precio: (
        <H6>
          <CurrencyFormat
            value={parseFloat(producto.precio)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'$'}
            decimalScale={3}
            fixedDecimalScale={true}
          />
        </H6>
      ),
      Borrar: (
        <BorrarIcon
          clearFunction={() => {
            handleDeleteCarrito(producto.id_articulo)
          }}
        />
      ),
    }
  })

const HeaderProveedores = ({
  onClick,
  proveedor,
  confirmFeedback,
  porcentajeRequisitos,
  cronogramaHabilitado,
}) => {
  const navigate = useNavigate()
  const { openModal, isModalOpen, closeModal } = useModal()
  const [isDeleting, setIsDeleting] = useState(false)
  const [isConfirmando, setIsConfirmando] = useState(false)
  const { user } = useAuth()
  const { finalizar } = useCarrito()

  const onClickComprarProveedores = async (idProveedor) => {
    navigate(
      `/comprar/${replaceText(
        proveedor.cabecera.condiciones.nombre_comercial,
        ' ',
        '-',
      )}/id/${idProveedor}`,
    )
  }

  const onClickConfirmar = async () => {
    setIsConfirmando(true)
    setIsDeleting(false)
    openModal()
  }

  const onClickRemover = async () => {
    setIsDeleting(true)
    setIsConfirmando(false)
    openModal()
  }

  const totales = calcularTotales(proveedor.articulos)

  const afterClose = () => {
    setIsConfirmando(false)
    setIsDeleting(false)
  }

  return (
    <StyledHeaderProveedores
      onClick={() => onClick(proveedor.cabecera.condiciones.nombre_comercial)}
    >
      <ProveedoresItem>
        <div>
          <H3>{proveedor.cabecera.condiciones.nombre_comercial}</H3>
          <LinkMenu
            onClick={() =>
              navigateToProveedorPage(
                proveedor.cabecera.condiciones.id,
                proveedor.cabecera.condiciones.nombre_comercial,
                navigate,
              )
            }
          >
            {'Ver requisitos'}
          </LinkMenu>
        </div>
      </ProveedoresItem>
      <ContainerInfo>
        {porcentajeRequisitos !== null && (
          <BultosItem
            porcentaje={porcentajeRequisitos}
            tipoProveedor={proveedor?.cabecera?.condiciones?.tipo_proveedor}
          />
        )}
        <InfoGridBox totales={totales} user={user} />
        <StyledButtonSection>
          <Button
            text="Seguir comprando"
            type="secondary"
            onClick={() =>
              onClickComprarProveedores(proveedor.cabecera.condiciones.id)
            }
          />
          <Button
            text={user.rol.id_rol === '3' ? 'Consolidar' : 'Confirmar'}
            onClick={() => onClickConfirmar(proveedor.cabecera.condiciones.id)}
            disabled={
              (user.rol.id_rol === '3' &&
                proveedor.cabecera.condiciones.tipo_proveedor === '1' &&
                porcentajeRequisitos < 100) ||
              !cronogramaHabilitado
            }
          />
          <IconButton
            icon={
              <DeleteIcon
                size={16}
                onClick={() =>
                  onClickRemover(proveedor.cabecera.condiciones.id)
                }
              />
            }
          />
        </StyledButtonSection>

        {isModalOpen && isConfirmando ? (
          user.rol.id_rol === '3' ? (
            <PedidosProvider>
              <ConsolidarAsociadaModal
                proveedorId={proveedor.cabecera.condiciones.id}
                lugares={user.lugares_entrega}
                confirmFeedback={confirmFeedback}
                afterClose={afterClose}
              />
            </PedidosProvider>
          ) : (
            <ModalNotification
              type="notificacion"
              title="Estás por confirmar el carrito"
              descripcion={[
                'Si seleccionas CONTINUAR ',
                'confirmarás el pedido de este proveedor particular. ',
                'Si no estas seguro seleccioná VOLVER Y REVISAR',
              ]}
              primaryFunction={async () => {
                await finalizar({
                  idProveedor: proveedor.cabecera.condiciones.id,
                })
                confirmFeedback()
                closeModal()
              }}
              secondaryFunction={() => {
                closeModal()
              }}
              afterClose={() => {}}
            />
          )
        ) : (
          <></>
        )}

        {isModalOpen && isDeleting ? (
          <DeleteProveedorModal
            idProveedor={proveedor.cabecera.condiciones.id}
            afterClose={afterClose}
          />
        ) : (
          <></>
        )}
      </ContainerInfo>
    </StyledHeaderProveedores>
  )
}

const BultosItem = ({ porcentaje, tipoProveedor }) => (
  <StyledBultosItem>
    <BtnS>REQUERIMIENTOS MÍNIMOS</BtnS>
    <ProgressBarBultos progress={porcentaje} tipoProveedor={tipoProveedor} />
  </StyledBultosItem>
)

const InfoGridBox = ({ totales }) => (
  <StyledInfoGridBox>
    <InfoBox>
      <BtnS>BULTOS</BtnS>
      <P1>{totales.bultos}</P1>
    </InfoBox>
    <Line $vertical="true" />
    <InfoBox>
      <BtnS>NETO</BtnS>
      <P1>
        <CurrencyFormat
          value={totales.subtotal}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          decimalScale={3}
          fixedDecimalScale={true}
        />
      </P1>
    </InfoBox>
    <Line $vertical="true" />
    <InfoBox>
      <BtnS>TOTAL</BtnS>
      <P1>
        <CurrencyFormat
          value={totales.total}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          decimalScale={3}
          fixedDecimalScale={true}
        />
      </P1>
    </InfoBox>
  </StyledInfoGridBox>
)

const CarritoTable = ({ confirmFeedback }) => {
  const [expandedState, setExpandedState] = useState({})
  const { agregarItem, carrito } = useCarrito()
  const { getArticulos } = useArticulos()
  const { width } = useWindowWidth()
  const { openModal, isModalOpen } = useModal()
  const { cronograma } = useCronograma()
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] =
    useState(false)
  const [idProductDeleted, setIdProductDeleted] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()

  const headers = [
    ...(width > 440
      ? [
          {
            name: '',
            isOrderable: false,
          },
        ]
      : []),
    {
      name: 'Producto',
      isOrderable: false,
    },
    {
      name: 'Uni / bulto',
      isOrderable: false,
    },
    {
      name: 'Cantidad',
      isOrderable: false,
    },
    ...(width > 440
      ? [
          {
            name: 'Observacion',
            isOrderable: false,
          },
        ]
      : []),
    {
      name: 'Precio unitario',
      isOrderable: false,
    },
    {
      name: '',
      isOrderable: false,
    },
  ]

  const handleClick = (proveedorNombre) => {
    setExpandedState((prevState) => ({
      ...prevState,
      [proveedorNombre]: !prevState[proveedorNombre],
    }))
  }

  const afterClose = () => {
    setIsDeleteProductModalOpen(false)
  }

  const handleDeleteCarrito = (idProducto) => {
    openModal()
    setIsDeleteProductModalOpen(true)
    setIdProductDeleted(idProducto)
  }

  const handleAgregarItem = async (id_articulo, cant, idProveedor) => {
    try {
      await agregarItem(id_articulo, cant, idProveedor)
      showToast(`Artículo modificado en el carrito!`, 'validacion')
    } catch (error) {
      console.error('Error al actualizar el carrito:', error)
      await getArticulos(idProveedor)
    }
  }

  function calcularPorcentajeRequisitos(proveedor, totales) {
    // Obtiene los valores de bultos_minimo y valorizado_minimo
    const formatearRequisitos = (porcentaje) => {
      return porcentaje > 0 && porcentaje < 1 ? 1 : Math.round(porcentaje)
    }
    const bultosMinimo = proveedor?.cabecera?.minimo_log?.bultos_minimo
    const valorizadoMinimo = proveedor?.cabecera?.minimo_log?.valorizado_minimo
    const palletMinimo = proveedor?.cabecera?.minimo_log?.pallet_minimo
    // Inicializa el porcentaje en 100 por defecto
    let porcentajeRequisitos = 100

    if (bultosMinimo && bultosMinimo !== '0') {
      porcentajeRequisitos = Math.round((totales.bultos / bultosMinimo) * 100)
    } else if (valorizadoMinimo && valorizadoMinimo !== '0') {
      porcentajeRequisitos = Math.round(
        (totales.subtotal_float / valorizadoMinimo) * 100,
      )
    } else if (palletMinimo && palletMinimo !== '0') {
      porcentajeRequisitos = (totales.pallets / palletMinimo) * 100
    }

    return formatearRequisitos(porcentajeRequisitos)
  }

  function calcularCumplimientoMultiplos(proveedor) {
    let cumpleMultiplos = true
    proveedor.articulos.forEach((articulo) => {
      cumpleMultiplos &&=
        parseInt(articulo.cantidad_carrito) % parseInt(articulo.multiplo) === 0
    })

    return cumpleMultiplos
  }
  return (
    <>
      {carrito &&
      Object.keys(carrito).length !== 0 &&
      carrito.carritos &&
      carrito.carritos.length > 0 ? (
        <TableWrapper>
          {carrito.carritos.map((proveedor) => {
            const isExpanded =
              expandedState[proveedor.cabecera.condiciones.nombre_comercial] ||
              true
            const totales = calcularTotales(proveedor.articulos)
            const totalPrecios = sumarPrecios(proveedor)
            const porcentajeRequisitos = calcularPorcentajeRequisitos(
              proveedor,
              totales,
            )
            const cumpleMultiplos = calcularCumplimientoMultiplos(proveedor)
            return (
              <div key={proveedor?.cabecera?.condiciones?.nombre_comercial}>
                <HeaderProveedores
                  $isExpanded={isExpanded}
                  onClick={handleClick}
                  proveedor={proveedor}
                  totalPrecios={totalPrecios}
                  confirmFeedback={confirmFeedback}
                  porcentajeRequisitos={porcentajeRequisitos}
                  cronogramaHabilitado={cronograma.habilitado}
                />
                {proveedor.articulos?.length > 0 ? (
                  <>
                    {isExpanded && (
                      <HeaderTable
                        numerate={false}
                        headers={headers}
                        gridTemplate={
                          width > 440
                            ? '10% 30% 10% 15% 15% 10% 5%'
                            : '24% 12% 24% 18% 10%'
                        }
                      />
                    )}
                    <TableStyled
                      $isExpanded={isExpanded}
                      data={formatData(
                        proveedor.articulos,
                        proveedor.id,
                        proveedor?.cabecera?.condiciones?.nombre_comercial,
                        handleAgregarItem,
                        handleDeleteCarrito,
                        user,
                        width,
                        navigate,
                      )}
                      numerate={false}
                      gridTemplate={
                        width > 440
                          ? '10% 30% 10% 15% 15% 10% 5%'
                          : '26% 6% 26% 20% 8%'
                      }
                    />
                    {!cronograma.habilitado ? (
                      <DropdownNotification
                        text="El cronograma no se encuentra habilitado."
                        type="alerta"
                      />
                    ) : porcentajeRequisitos < 100 ? (
                      <DropdownNotification
                        text="No se cumple con los requisitos mínimos del proveedor."
                        type={
                          user.rol.id_rol === '3' &&
                          proveedor.cabecera.condiciones.tipo_proveedor === '1'
                            ? 'error'
                            : 'alerta'
                        }
                        action={() =>
                          navigateToProveedorPage(
                            proveedor.cabecera.condiciones.id,
                            proveedor.cabecera.condiciones.nombre_comercial,
                            navigate,
                          )
                        }
                        actionText="Ver requisitos"
                      />
                    ) : (
                      <></>
                    )}
                    {!cumpleMultiplos ? (
                      <DropdownNotification
                        text="No se cumple con los múltiplos de alguno/s de los artículos del proveedor."
                        type={
                          user.rol.id_rol === '3' &&
                          proveedor.cabecera.condiciones.tipo_proveedor === '1'
                            ? 'error'
                            : 'alerta'
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <EmptyTableData>
                    No hay productos en el carrito aún
                  </EmptyTableData>
                )}
              </div>
            )
          })}
        </TableWrapper>
      ) : (
        <TableWrapper>
          <EmptyPage>
            <EmptyTableData>No hay productos en el carrito aún</EmptyTableData>
            <ButtonIrAComprar
              onClick={() => navigate('/comprar')}
              type="secondary"
              text="Ir a Comprar"
              size="small"
            />
          </EmptyPage>
        </TableWrapper>
      )}
      {isModalOpen && isDeleteProductModalOpen && (
        <DeleteProductModal
          idProducto={idProductDeleted}
          afterClose={afterClose}
        />
      )}
    </>
  )
}

export default CarritoTable
