import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { P2, P4 } from 'components/Typography'
import Table, { TableRow } from 'components/Table/Table'
import { useSearchAndFilterTable } from 'contexts/SearchAndFilterTableContext'
import HeaderTable from 'components/Table/HeaderTable'
import { ShoppingCart } from 'feather-icons-react/build/IconComponents'
import CounterInput from 'components/CounterInput/CounterInput'
import { useCarrito } from 'contexts/CarritoContext'
import Link from 'components/Button/LinkUnderline'
import { useNavigate } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import { useArticulos } from 'contexts/ArticulosContext'
import StatusTag from 'components/Tag/StatusTag'
import { Tooltip } from 'react-tooltip'
import { showToast } from 'components/Notification/ToastNotification'
import { compressLongText, isLongText, replaceText } from 'utils/textProcess'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const LinkTable = styled(Link)`
  line-break: anywhere;
`

const ShoppingCartIcon = styled(ShoppingCart)`
  margin: 30px 20px;
`

const CustomHeader = styled(HeaderTable)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  @media (min-width: 440px) and(max-width: 1366px) {
    grid-template-columns: 8% 18% 8% 10% 6% 6% 8% 10% 8%;
  }
`

const CustomTable = styled(Table)`
  ${TableRow} {
    @media (min-width: 440px) and(max-width: 1366px) {
      grid-template-columns: 8% 18% 8% 10% 6% 6% 8% 10% 8%;
    }
  }
`

const SubTitleTable = styled(P2)`
  margin: 15px;
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  text-align: center;
`

const ScrolleableTable = styled.div`
  height: 50vh;
  overflow: auto;
`

const TableCell = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ $precioReal }) => ($precioReal ? 'pointer' : 'initial')};
`

const VerticalTableCell = styled(TableCell)`
  flex-direction: column;
  align-items: start;
  gap: 6px;
`

const CustomStatusTag = styled(StatusTag)`
  font-size: 12px;
`

const PromoTag = styled(CustomStatusTag)`
  background-color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const FooterSection = styled.div`
  display: flex;
`

const isEstadoSuspendido = (estado) => {
  switch (estado) {
    case '1':
      return false // Activo
    case 2:
    case 3:
    case 10:
    case 31:
    default:
      return true // Suspendido
  }
}

const devolverPrecioReal = (articulo) => {
  const precioReal =
    parseFloat(articulo.precio_real).toLocaleString('es-ar', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 3,
    }) +
    ' - ' +
    parseFloat(articulo.precio_real_rl).toLocaleString('es-ar', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 3,
    })

  return precioReal
}

const IdCol = styled.div`
  display: block;
`

function actualizarCantidadCarrito(data, id, id_articulo, cantidad) {
  const categoria = data.find((cat) => cat.id === id)

  if (!categoria) {
    return data
  }

  const articulo = categoria.articulos.find(
    (art) => art.id_articulo === id_articulo,
  )

  if (!articulo) {
    return data
  }

  articulo.cantidad_carrito = cantidad

  return data
}

const formatData = (
  { articulos, puedePedir, categoriaId },
  onClickActualizarCarrito,
  navigateToProductoPage,
  width,
) =>
  articulos.map((e) => ({
    ...(width > 440 && {
      Categoria: (
        <TableCell>
          <CustomStatusTag
            estado={isEstadoSuspendido(e.estado) ? 'Suspendido' : 'Activo'}
          />
        </TableCell>
      ),
    }),
    Producto: (
      <>
        <VerticalTableCell
          data-tooltip-id={
            isLongText(e.descripcion, 24) ? 'productoDescripcion' : undefined
          }
          data-tooltip-content={
            isLongText(e.descripcion, 24) ? e.descripcion : undefined
          }
          data-tooltip-place="top"
        >
          {e.id_promocion !== '0' && <PromoTag estado={'PROMO'} />}
          <LinkTable onClick={() => navigateToProductoPage(e.id_articulo)}>
            {compressLongText(e.descripcion, 24)}
          </LinkTable>
        </VerticalTableCell>
        {isLongText(e.descripcion, 24) && <Tooltip id="productoDescripcion" />}
      </>
    ),
    'UNI/MEDIDA': (
      <TableCell>
        <P4>{e.gramaje + ' ' + e.unidad_medida}</P4>
      </TableCell>
    ),
    ...(width > 440 && {
      EAN: (
        <TableCell>
          {e.id_col && e.id_col !== '0' ? (
            <>
              <IdCol>
                <P4>{e.ean13}</P4>
                <P4>({e.id_col})</P4>
              </IdCol>
            </>
          ) : (
            <P4>{e.ean13}</P4>
          )}
        </TableCell>
      ),
      'UNI/BULTO': (
        <TableCell>
          <P4>{e.bultos_camada}</P4>
        </TableCell>
      ),
      'BULTO/PALLETS': (
        <TableCell>
          <P4>{e.bultos_piso * e.pisos_pallet}</P4>
        </TableCell>
      ),
    }),
    PRECIO: (
      <>
        <TableCell
          data-tooltip-id="Precio"
          data-tooltip-content={e.precio_real ? devolverPrecioReal(e) : ''}
          data-tooltip-place="top"
          $precioReal={e.precio_real}
        >
          <P4>
            <CurrencyFormat
              value={parseFloat(e.precio)}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={' $'}
              decimalScale={3}
              fixedDecimalScale={true}
            />
          </P4>
        </TableCell>
        <Tooltip id="Precio" />
      </>
    ),
    ...(width > 440 && {
      OBSERVACIÓN: (
        <TableCell>
          <P4
            title={`${e.multiplo !== '0' && e.multiplo !== '1' ? `Múltiplo: ${e.multiplo}` : ''}${
              e.multiplo !== '0' &&
              e.multiplo !== '1' &&
              e.observacion &&
              e.observacion.length > 0
                ? ' - '
                : ''
            }${e.observacion && e.observacion.length > 0 ? ` ${e.observacion}` : ''}`}
          >
            {e.multiplo !== '0' && e.multiplo !== '1'
              ? `Múltiplo: ${e.multiplo}`
              : ''}
            {e.multiplo !== '0' &&
            e.multiplo !== '1' &&
            e.observacion &&
            e.observacion.length > 0
              ? ' - '
              : ''}
            {e.observacion && e.observacion.length > 0
              ? ` ${compressLongText(e.observacion, 34)}`
              : ''}
          </P4>
        </TableCell>
      ),
    }),
    CANTIDAD: (
      <TableCell>
        <CounterInput
          amount={e.cantidad_carrito}
          onClick={async (cant) => {
            await onClickActualizarCarrito(categoriaId, e.id_articulo, cant)
            showToast(`Artículo modificado en el carrito!`, 'validacion')
          }}
          disabled={!puedePedir || isEstadoSuspendido(e.estado)}
          // multiplo={parseInt(e.multiplo)}
        />
      </TableCell>
    ),
  }))

const ProductosTable = ({ puedePedir, nombreProveedor, idProveedor }) => {
  const { agregarItem } = useCarrito()
  const { setArticulos, articulos, getArticulos } = useArticulos()
  const { width } = useWindowWidth()
  const { data } = useSearchAndFilterTable()
  const navigate = useNavigate()
  const tableRef = useRef(null)

  useEffect(() => {
    const tableCurrent = tableRef.current
    const prevPosition = localStorage.getItem('productosTableScrollPosition')

    if (prevPosition !== null && tableCurrent?.scrollTop) {
      tableCurrent.scrollTop = parseFloat(prevPosition)
    }

    const handleScroll = () => {
      const currentScrollPosition = tableRef.current.scrollTop
      localStorage.setItem(
        'productosTableScrollPosition',
        currentScrollPosition,
      )
    }

    if (tableCurrent) {
      tableCurrent.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (tableCurrent) {
        tableCurrent.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const navigateToProductoPage = async (idArticulo) => {
    navigate(`/comprar/${replaceText(nombreProveedor, ' ', '-')}/${idArticulo}`)
  }

  const handleAgregarArticulos = async (
    id_categoria,
    id_articulo,
    cantidad,
  ) => {
    try {
      await agregarItem(id_articulo, cantidad, idProveedor)
    } catch (error) {
      console.error('Error al actualizar el carrito:', error)
      await getArticulos(idProveedor)
    }
    setArticulos({
      ...articulos,
      articulos: actualizarCantidadCarrito(
        articulos.articulos,
        id_categoria,
        id_articulo,
        cantidad,
      ),
    })
  }

  const headers = [
    ...(width > 440 ? [{ name: 'Estado', isOrderable: false }] : []),
    { name: 'Producto', isOrderable: false },
    { name: 'Uni / Medida', isOrderable: false },
    ...(width > 440
      ? [
          { name: 'EAN', isOrderable: false },
          { name: 'Uni / Bulto', isOrderable: false },
          { name: 'Bulto / Pallets', isOrderable: false },
        ]
      : []),
    { name: 'Precio Neto', isOrderable: false },
    ...(width > 440 ? [{ name: 'Observación', isOrderable: false }] : []),
    { name: 'Cantidad', isOrderable: false },
  ]

  return (
    <>
      <CustomHeader
        numerate={false}
        headers={headers}
        gridTemplate={
          width > 440 ? '8% 18% 6% 12% 6% 6% 10% 10% 10%' : '30% 16% 20% 10%'
        }
      />
      {data?.length > 0 ? (
        <ScrolleableTable ref={tableRef}>
          {data
            .filter((categoria) => categoria.articulos.length > 0)
            .map((categoria, index) => (
              <div key={index}>
                <SubTitleTable>{categoria.descripcion}</SubTitleTable>
                <CustomTable
                  data={formatData(
                    {
                      categoria: categoria.descripcion,
                      articulos: categoria.articulos.sort(
                        (a, b) =>
                          isEstadoSuspendido(a.estado) -
                          isEstadoSuspendido(b.estado),
                      ),
                      puedePedir,
                      categoriaId: categoria.id,
                    },
                    handleAgregarArticulos,
                    navigateToProductoPage,
                    width,
                  )}
                  numerate={false}
                  gridTemplate={
                    width > 440
                      ? '8% 18% 6% 12% 6% 6% 10% 10% 10%'
                      : '32% 12% 18% 10%'
                  }
                >
                  <Tooltip id="producto-descripcion" />
                  <Tooltip id="categoria-descripcion" />
                </CustomTable>
              </div>
            ))}
        </ScrolleableTable>
      ) : (
        <EmptyTableData>No se encontraron articulos</EmptyTableData>
      )}
      <FooterSection>
        <Link href="/mi-carrito">
          <ShoppingCartIcon /> Ver mi carrito
        </Link>
      </FooterSection>
    </>
  )
}

export default ProductosTable
