import React from 'react'
import styled from 'styled-components'

import Tag from 'components/Tag/Tag'
import UserImage from 'components/User/UserImage'
import Button from 'components/Button/Button'
import { H4 } from 'components/Typography'
import { useNavigate } from 'react-router-dom'
import { compressLongText, replaceText } from 'utils/textProcess'

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 192px;
  height: auto;
  margin: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 2px solid ${({ theme }) => theme.colors.primary.darkBlue};
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:first-child {
    margin-left: 0;
  }
`

const TagContainer = styled.div`
  display: inline-flex;
  gap: 6px;
  margin: 8px 0;
  flex-wrap: wrap;
`

const CardUserImageContainer = styled.div`
  margin-top: 12px;
`

const CardTitle = styled(H4)`
  font-size: 20px;
  margin-top: 6px;
  line-height: 1.3;
`

const CardButton = styled(Button)`
  display: flex;
  align-self: flex-end;
`

const FeaturedTag = styled(Tag)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const Card = ({ card }) => {
  const firstThreeTags = card.botTags ? card.botTags.slice(0, 3) : []
  const navigate = useNavigate()

  return (
    <StyledCard>
      {card.topTag && <FeaturedTag type="tertiary">{card.topTag}</FeaturedTag>}
      <CardUserImageContainer>
        <UserImage src={card.path_thumb} alt="imagen de perfil" />
      </CardUserImageContainer>
      <CardTitle>{compressLongText(card.title, 35)}</CardTitle>
      {card.botTags && (
        <TagContainer>
          {firstThreeTags.map((tag) => (
            <Tag key={tag.id} type={tag.type}>
              {tag.text}
            </Tag>
          ))}
        </TagContainer>
      )}
      {card.categorias && (
        <TagContainer>
          {card.categorias.map((tag, index) => (
            <Tag key={index} type="secondary">
              {tag.descripcion}
            </Tag>
          ))}
        </TagContainer>
      )}
      <CardButton
        onClick={
          card.navigateFunction
            ? () => card.navigateFunction()
            : card.id
              ? () =>
                  navigate(
                    `/comprar/${replaceText(card.title, ' ', '-')}/id/${card.id}`,
                  )
              : ''
        }
        type={
          (card.habilitado === 1 && card.puedePedir.puedePedir) || !card.id
            ? 'primary'
            : 'secondary'
        }
        text={
          (card.habilitado === 1 && card.puedePedir.puedePedir) || !card.id
            ? 'Comprar'
            : 'Ver surtido'
        }
        size="small"
      />
    </StyledCard>
  )
}

export default Card
